@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;  
 
body{
  font-family: 'inter' ,sans-serif;
}
.app{
  background-image: url(/public//homeimage/homeimage3\ \(2\).png);
  background-color: black;

}



button{
animation:btn 1s ease forwards;
}


@keyframes btn{
  0%{
    transform: translateY(100px);
    opacity: 0;
  }
  100%{
    transform: translateY(0px);
    opacity: 1;
  }
}
.card1{
  
  animation:card1 1s   ease forwards;
  
  }
  @keyframes card1{
    0%{
      transform: translateX(-100px);
      opacity: 0;
    }
    100%{
      transform: translateX(0px);
      opacity: 1;
    }
  }
  .card2{
  
    animation:card1 2s  ease forwards;
    
    }
    @keyframes card1{
      0%{
        transform: translateX(-100px);
        opacity: 0;
      }
      100%{
        transform: translateX(0px);
        opacity: 1;
      }
    }
    .card3{
  
      animation:card1  3s  ease forwards;
      
      }
      @keyframes card1{
        0%{
          transform: translateX(-100px);
          opacity: 0;
        }
        100%{
          transform: translateX(0px);
          opacity: 1;
        }
      }
      .heading1{
  
        animation:card1 4s   ease forwards;
        
        }
        @keyframes heading1{
          0%{
            transform: translateX(-200px);
            opacity: 0;
          }
          100%{
            transform: translateX(0px);
            opacity: 1;
          }
        }
        .box{
          background-color: #9a2935;
        }
        .circle{
          background-color: #a24b68;
          border-radius: 465px 40px 340px 370px;
          position: relative;
          left: 2px;
          top: 1px;
        }
     